import { Form, Modal, message, DatePicker } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HideLoading, ReloadData, ShowLoading } from "../../redux/rootSlice";
import axios from "axios";
import moment from "moment";

export default function AdminWork() {
  const dispatch = useDispatch();
  const { portfolioData } = useSelector((state) => state.root);
  const { projects } = portfolioData;
  const [showAddEditModal, setShowAddEditModal] = React.useState(false);
  const [selectedItemForEdit, setSelectedItemForEdit] = React.useState(null);
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [type, setType] = React.useState("add");

  useEffect(() => {
    if (openAddModal) {
      setSelectedItemForEdit(null);
      setType('add');
      setShowAddEditModal(true);
      setOpenAddModal(false); // reset the trigger
    }
  }, [openAddModal]);

  useEffect(() => {
    if (openEditModal) {
      setType('edit');
      setShowAddEditModal(true);
      setOpenEditModal(false); // reset the trigger
    }
  }, [openEditModal]);

  const onFinish = async (values) => {
    try {
      const tempSkills = values?.skills?.split(', ') || [];
      values.skills = tempSkills;

      // Format dates to a more standard string format before sending to the server
      if (values.startDate) {
        values.startDate = values.startDate.format('YYYY-MM');
      }
      if (values.endDate) {
        values.endDate = values.endDate.format('YYYY-MM');
      }

      dispatch(ShowLoading());
      let response;
      if (selectedItemForEdit) {
        response = await axios.post("/api/portfolio/update-project", {
          ...values,
          _id: selectedItemForEdit._id,
        });
      } else {
        response = await axios.post("/api/portfolio/add-project", values);
      }
      dispatch(HideLoading());
      if (response.data.success) {
        message.success(response.data.message);
        setShowAddEditModal(false);
        setSelectedItemForEdit(null);
        dispatch(ReloadData(true));
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const onDelete = async (item) => {
    try {
      dispatch(ShowLoading());
      const response = await axios.post("/api/portfolio/delete-project", {
        _id: item._id,
      });
      dispatch(HideLoading());
      if (response.data.success) {
        message.success(response.data.message);
        dispatch(ReloadData(true));
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const handleEdit = (project) => {
    setSelectedItemForEdit(project);
    setOpenEditModal(true);
  };

  return (
    <div>
      <div className="flex justify-end mb-5">
        <button
          className="bg-gold border-2 border-blue px-5 py-2 text-blue"
          onClick={() => {
            setOpenAddModal(true);
          }}
        >
          Add Project
        </button>
      </div>
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-5">
        {projects.map((project) => (
          <div className="shadow border border-gray-400 p-5 flex flex-col" key={project._id}>
            <h1 className="text-blue text-xl font-bold">{project.name}</h1>
            <hr />
            <h1 className="text-lg mt-2">{project.period}</h1>
            <h1 className="font-bold mt-2">Skills</h1>
            <h1 className="text-sm"> {project.skills.join(", ")}</h1>
            <h1 className="font-bold mt-2">Description</h1>
            <p className="text-sm">{project.description}</p>
            <h1 className="font-bold">Github:</h1>
            <p className="text-sm">{project.github}</p>
            <div className="flex justify-end gap-5 mt-5">
              <button
                className="bg-gold text-blue border-2 border-blue px-5 py-2"
                onClick={() => {
                  onDelete(project);
                }}
              >
                Delete
              </button>
              <button
                className="bg-black text-white px-5 py-2"
                onClick={() => {
                  handleEdit(project);
                }}
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>
      {(showAddEditModal && (type === "add" || selectedItemForEdit)) && (
        <Modal
          title={selectedItemForEdit ? "Edit Project" : "Add Project"}
          open={showAddEditModal}
          onCancel={() => {
            setShowAddEditModal(false);
            setSelectedItemForEdit(null);
          }}
          footer={null}
        >
          <Form
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              ...selectedItemForEdit,
              skills: selectedItemForEdit?.skills?.join(', '),
              startDate: selectedItemForEdit?.startDate ? moment(selectedItemForEdit.startDate, 'YYYY-MM') : null,
              endDate: selectedItemForEdit?.endDate ? moment(selectedItemForEdit.endDate, 'YYYY-MM') : null
            }}
          >
            <Form.Item name="name" label="Name">
              <input placeholder="Name" />
            </Form.Item>
            <Form.Item name="period" label="Period">
              <input placeholder="Period" />
            </Form.Item>
            <Form.Item name="startDate" label="Start Date">
              <DatePicker picker="month" format="YYYY-MM" />
            </Form.Item>
            <Form.Item name="endDate" label="End Date">
              <DatePicker picker="month" format="YYYY-MM" />
            </Form.Item>
            <Form.Item name="skills" label="Skills">
              <input placeholder="Skills" />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <textarea placeholder="Description" />
            </Form.Item>
            <Form.Item name="github" label="Github">
              <input placeholder="GitHub" />
            </Form.Item>
            <div className="flex justify-end gap-3 mt-5">
              <button className="bg-gold text-white px-5 py-2">
                {selectedItemForEdit ? "Update" : "Add"}
              </button>
            </div>
          </Form>
        </Modal>
      )}
    </div>
  );
}